<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
  html,body{
    margin:0;
    padding: 0;
  }

#app {
  height: 98vh;
}
</style>
