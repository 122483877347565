<template>
    <el-container style="height: 100%;">
    <el-aside width="auto">
        <CommonAside ref="aside" @addTab="addTab"/>
    </el-aside>
    <el-container>
        <el-header>
            <CommonHeader ref="headerbread" @handleTabs="handleTabs" @handleStar="handleStar" />
        </el-header>
        <el-main>
            <CommonMain ref="maintabs" @setBreadcrumb="setBreadcrumb"/>
        </el-main>
    </el-container> 
    </el-container>
</template>

<script>
    import CommonAside from '@/components/CommonAside.vue';
    import CommonHeader from '@/components/CommonHeader.vue';
    import CommonMain from '@/components/CommonMain.vue';
    export default{
        name:'Home',
        components:{
            CommonAside,
            CommonHeader,
            CommonMain
        },
        data(){
            return {}
        },
        mounted(){
			var funid = this.$cookies.get("funid");
			
			
            var sessionid = this.$cookies.get("sessionidd");
            if(sessionid===null){
                window.location.href="/loginPage";
                //this.$router.push('/loginPage');
            }
        },
        methods:{
            addTab(item) {
                this.$refs.maintabs.addTab(item);
            },
            handleTabs(command) {
                this.$refs.maintabs.handleTabs(command);
            },
            setBreadcrumb(item) {
                this.$refs.headerbread.setBreadcrumb(item);
            },
            handleStar(){
                this.$refs.maintabs.handleStar();
            }
        }
    }
</script>
<style lang ="less" scoped>
    .el-header{
        background-color: #FFFFFF;
    }

    .el-main{
        padding-top: 0;
        overflow-y:hidden
    }
    

</style>