<template>
  <div class="sidebar">
    <el-menu class="el-menu-vertical-demo" :default-active="activeMenuIndex" :collapse="collapse"
             background-color="#FFFFFF" text-color="#515A6E" active-text-color="#1890FF" unique-opened:false>
      <div class="collapse" @click="collapseChage">
        <el-tooltip effect="dark" :content="collapse?`展开导航栏`:`折叠导航栏`" placement="right">
          <i :class="[ collapse? 'el-icon-s-unfold' :'el-icon-s-fold']" style="color:#409EFF;font-size:medium;"></i>
        </el-tooltip>
      </div>
      <div class="logo"><img class="img" src="../assets/logo.png"/>{{ platformName }}</div>
      <el-divider></el-divider>
      <div class="search" style="padding-bottom: 10px;">
        <el-select v-if="!collapse" v-model="value" filterable remote reserve-keyword placeholder="快捷搜索" :remote-method="remoteMethod" :loading="loading" clearable @change="searchSelect">
          <el-option v-for="item in options" :key="item.ID" :label="item.title" :value="item.ID">
            <span><i :class="item.icon" style="color:#515A6E;font-size:medium;"></i>&nbsp;{{ item.title }}</span>
          </el-option>
        </el-select>
        <!-- <el-button icon="el-icon-delete" circle type="info" plain  onclick="this.value=''"></el-button> -->
      </div>

      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <i :class="item.icon"></i><span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <span slot="title"><i :class="subItem.icon"></i>{{ subItem.title }}</span>
                <el-menu-item v-for="(threeItem,i) in subItem.subs" :key="threeItem.index" :index="threeItem.index"
                              @click=menuClick(threeItem)>
                  <span slot="title"><i :class="threeItem.icon"></i>{{ threeItem.title }}<i v-if="subItem.star"
                                                                                            class="el-icon-star-on"></i></span>
                </el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.index" @click=menuClick(subItem)>
                <span slot="title"><i :class="subItem.icon"></i>{{ subItem.title }}<i v-if="subItem.star"
                                                                                      class="el-icon-star-on"></i></span>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" @click=menuClick(item)>
            <span slot="title"><i :class="item.icon"></i>{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
  methods: {
    setBreadcrumb(item) {
      this.$emit("setBreadcrumb", item);
    },
    menuClick(item) {
      this.$emit("addTab", item);
    },
    collapseChage() {
      this.collapse = !this.collapse;
      if (this.collapse) {
        this.platformName = '';
      } else {
        this.platformName = '石特综合业务平台';
      }
    },
    searchSelect(ID) {
      var searchParams = {
        req: "m11Req",
        page: 1,
        rows: 50,
        sort: "ID",
        order: "asc",
        pid: '',
        filterRules: '[{"field":"ID","op":"contains","value":"' + ID + '"}]'
      }
      let that = this;
      this.$http.post(this.url + '/imc/queryData', this.$qs.stringify(searchParams)).then(function (response) {
        that.item.index = response.data[0].ID.toString();
        that.item.title = response.data[0].title;
        that.item.url = response.data[0].url;
        that.item.icon = response.data[0].icon;
        that.item.openType = response.data[0].openType;
        that.activeMenuIndex = that.item.index;
        that.$emit("addTab", that.item);
      }).catch(function (error) {
        console.log(error);
      });
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        var searchParams = {
          req: "m11Req",
          page: 1,
          rows: 50,
          sort: "ID",
          order: "asc",
          pid: '',
          filterRules: '[{"field":"title","op":"contains","value":"' + query + '"}]'
        }
        let that = this;
        this.$http.post(this.url + '/imc/queryData', this.$qs.stringify(searchParams)).then(function (response) {
          that.options = response.data;
          that.loading = false;
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  },
  data() {
    return {
      url: '',
      collapse: false,
      activeMenuIndex: '',
      item: {
        index: '',
        title: '',
        url: '',
        icon: ''
      },
      options: [],
      value: '',
      loading: false,
      navParams: {
        req: "m9Req",
        page: 1,
        rows: 50,
        sort: "ID",
        order: "asc",
        pid: ''
      },
      platformName: ' 石特综合业务平台',
      items: []
    }
  },
  computed: {
    //   onRoutes(){
    //       return this.$route.path.replace('/','');
    //   }
  },
  mounted() {
	var funid = this.$cookies.get("funid");
	if(funid != '0'){
		this.searchSelect(funid);
		this.$cookies.remove("funid");
	}
			
  
    let that = this;
    this.$http.post(this.url + '/imc/queryData', this.$qs.stringify(this.navParams)).then(function (response) {
      that.items = response.data;
    }).catch(function (error) {
      console.log(error);
    });
  }
}
</script>

<style scoped>
span {
  justify-content: center;
  align-items: center;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 260px;
  min-height: 400px;
  font-family: Arial, Helvetica, sans-serif;
}

.sidebar {
  height: 98vh;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 260px;
}

.sidebar > ul {
  height: 100%;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 10px 5px;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  color: #363636;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 16px 5px;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  color: #363636;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img {
  width: 30px;
  background: white;
}
</style>