import Vue from 'vue'
import App from './App.vue'
import { Button, Select,Container,Header,Aside,Main,Footer,Menu,Submenu,MenuItem,MenuItemGroup,Divider,Link,Dropdown,DropdownMenu,DropdownItem,Tooltip,Tabs,TabPane,Option,OptionGroup,Breadcrumb,BreadcrumbItem,Form,FormItem,Input,InputNumber,Alert} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'
import router from './router'
import http from 'axios'
import qs from 'qs'
import VueCookies from 'vue-cookies'

Vue.prototype.$http = http;
Vue.prototype.$qs = qs;
Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(Button)
Vue.use(Select)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Container)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Divider)
Vue.use(Link)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tooltip)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Alert)


new Vue({
	router:router,
	render: h => h(App),
}).$mount('#app')
