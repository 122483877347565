<template>
    <div class="header">
        <div class="header-left">
            <el-breadcrumb separator=">">
            <el-breadcrumb-item v-for="item in path" :key="item.value">{{item}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 收藏 -->
                <!-- <div class="btn-fullscreen" @click="handleStar">
                    <el-tooltip effect="dark" content="收藏" placement="bottom">
                        <i class="el-icon-star-on" style="color:#828282;font-size:medium;"></i>
                    </el-tooltip>
                </div> -->
                <!-- 选项卡操作 -->
                <div class="btn-tabs">
                    <el-dropdown trigger="hover" @command="handleTabs">
                        <span class="el-icon-copy-document" style="color:#828282;font-size:medium;"></span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="one"><i class="el-icon-minus" style="color:#828282;font-size:medium;"></i>关闭当前</el-dropdown-item>                          
                            <el-dropdown-item command="other"><i class="el-icon-close" style="color:#828282;font-size:medium;"></i>关闭其他</el-dropdown-item>   
                            <el-dropdown-item command="left"><i class="el-icon-back" style="color:#828282;font-size:medium;"></i>关闭左侧</el-dropdown-item>   
                            <el-dropdown-item command="right"><i class="el-icon-right" style="color:#828282;font-size:medium;"></i>关闭右侧</el-dropdown-item>  
                            <el-dropdown-item command="all" divided><i class="el-icon-close" style="color:#828282;font-size:medium;"></i>关闭全部</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- 全屏显示 -->
                <div class="btn-fullscreen" @click="handleFullScreen">
                    <el-tooltip effect="dark" :content="fullscreen?`取消全屏`:`全屏`" placement="bottom">
                        <i class="el-icon-full-screen" style="color:#828282;font-size:medium;"></i>
                    </el-tooltip>
                </div>
                <!-- 消息中心 -->
                <div class="btn-bell">
                    <el-tooltip effect="dark" :content="message?`有${message}条未读消息`:`消息中心`" placement="bottom">
                        <router-link to="/tabs">
                            <i class="el-icon-bell" style="color:#828282;font-size:medium;"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span>
                </div>
                <!-- 用户头像 -->
                <div class="user-avator"></div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link" style="color:#828282;font-size:medium;">
                        {{username}} <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <a>
                            <el-dropdown-item>关于作者</el-dropdown-item>
                        </a>
                        <a>
                            <el-dropdown-item>项目仓库</el-dropdown-item>
                        </a> -->
                        <el-dropdown-item divided  command="loginout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <br>
        <!-- <div>
            <el-divider content-position="center"></el-divider>
        </div> -->
    </div>
</template>
<script>
    export default {
        data() {
            return {
                url:'',
                collapse: false,
                fullscreen: false,
                name: '系统',
                message: 2,
                path:[]
            }
        },
        computed:{
            username(){
                let username = localStorage.getItem('ms_username');
                return username ? username : this.name;
            }
        },
        methods:{
            setBreadcrumb(item){
                console.log(item);
                if(item===0)
                    this.path=[];
                else{
                    var searchParams = {
                        req: "m11Req",
                        page: 1,
                        rows: 50,
                        sort: "ID",
                        order: "asc",
                        pid: '',
                        filterRules:'[{"field":"ID","op":"contains","value":"'+item+'"}]'
                    }
                    let that = this;
                    this.$http.post(this.url+'/imc/queryData',this.$qs.stringify(searchParams)).then(function (response) {
                        that.path =(response.data[0].path.split('|'));
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            },
            // 用户名下拉菜单选择事件
            handleCommand(command) {
                if(command == 'loginout'){
                    localStorage.removeItem('ms_username')
                    this.$cookies.remove('sessionidd');
                    window.location.href="/loginPage";
                }
            },
            handleTabs(command){
                this.$emit("handleTabs",command);
            },
            handleStar(){
                this.$emit("handleStar");
            },
            // 全屏事件
            handleFullScreen(){
                let element = document.documentElement;
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                }
                this.fullscreen = !this.fullscreen;
            }
        }        
    }
</script>
<style scoped>
    .header {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        font-size: 22px;
        color: #fff;
    }
    .collapse-btn{
        float: left;
        padding: 0 21px;
        cursor: pointer;
        line-height: 70px;
    }
    .header .logo{
        float: left;
        width:250px;
        line-height: 70px;
    }
    .header-left{
        float: left;
        padding-top: 30px;
    }
    .header-right{
        float: right;
        padding-right: 50px;
    }
    .header-user-con{
        display: flex;
        height: 70px;
        align-items: center;
    }
    .btn-fullscreen{
        margin-right: 5px;
        font-size: 24px;
    }
    .btn-tabs{
        margin-right: 15px;
        font-size: 24px;
    }
    .btn-bell, .btn-fullscreen{
        position: relative;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
    }
    .btn-bell-badge{
        position: absolute;
        right: 0;
        top: -2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #f56c6c;
        color: #fff;
    }
    .btn-bell .el-icon-bell{
        color: #fff;
    }
    .user-name{
        margin-left: 10px;
    }
    .user-avator{
        margin-left: 20px;
    }
    .user-avator img{
        display: block;
        width:40px;
        height:40px;
        border-radius: 50%;
    }
    .el-dropdown-link{
        color: #fff;
        cursor: pointer;
    }
    .el-dropdown-menu__item{
        text-align: center;
    }

    
</style>
