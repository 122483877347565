<template>
  <el-tabs v-model="editableTabsValue" type="card" class='content-body' closable  @tab-remove="removeTab" @tab-click="clickTab">
  <!-- <el-tab-pane label="欢迎">欢迎</el-tab-pane> -->
  <el-tab-pane v-for="(item, index) in editableTabs" :key="item.name" :name="item.name">
    <span slot="label">
      <span class="span-box">
        <i :class="item.icon"></i>&nbsp;
        <span>{{ item.title }}</span>
      </span>
    </span>
    <iframe :src="item.url" style="width: 100%;height: 98%" frameborder="0"></iframe>
  </el-tab-pane>      
</el-tabs>
</template>

<script>
  
  export default {
    data() {
      return {
        editableTabsValue: '1',
        tabindex:-1,
        editableTabs: []
      }
    },
    computed: {
    },
    methods: {
      handleStar(){
        console.log();
      },  
      handleTabs(command){
        if(command==='all'){
          this.editableTabs=[];
        }
        if((command==='other')){
          let tabs = this.editableTabs;
          let activeName = this.editableTabsValue;
          tabs.forEach((tab, index) => {
            if (tab.name === activeName) {
              this.editableTabs = [];
              this.editableTabs.push(tab)
            }
          });
        }
        if(command==='one'){
          this.removeTab(this.editableTabsValue);
        }
        if(command==='right'){
          let tabs = this.editableTabs;
          this.tmpTabs  = [];
          this.editableTabs  = [];
          let activeName = this.editableTabsValue;
          this.tabindex =-1;
          tabs.forEach((tab, index) => {
            if (tab.name === activeName) {
              this.tabindex = index;
            }
          });
          tabs.forEach((tab, index) => {
            if (index<=this.tabindex) {
              this.editableTabs.push(tab);
            }
          });
        }
        if(command==='left'){
          let tabs = this.editableTabs;
          this.tmpTabs  = [];
          this.editableTabs  = [];
          let activeName = this.editableTabsValue;
          this.tabindex =-1;
          tabs.forEach((tab, index) => {
            if (tab.name === activeName) {
              this.tabindex = index;
            }
          });
          tabs.forEach((tab, index) => {
            if (index>=this.tabindex) {
              this.editableTabs.push(tab);
            }
          });
        }
        if(this.editableTabs.length!==0)
          this.$emit("setBreadcrumb",this.editableTabsValue);
        else
          this.$emit("setBreadcrumb",0);
      }, 
      clickTab(item){
        this.$emit("setBreadcrumb",item.name);
      },
      addTab(item) {
        if(item.openType==='tab'){
          let tabs = this.editableTabs;
            this.tabindex=-1;
            tabs.forEach((tab, index) => {
              if (tab.name === item.index) {
                this.tabindex = index;
              }
            });

            if (this.tabindex===-1){
              this.editableTabs.push({
                title: item.title,
                name: item.index,
                icon: item.icon,
                url: item.url,
                openType:item.openType
              });
            }
            this.editableTabsValue = item.index;
            this.$emit("setBreadcrumb",item.index);
        }
        if(item.openType==='self'){
          window.open(item.url, '_self')
        }
        if(item.openType==='blank'){
          window.open(item.url, '_blank')
        }
      },
      removeTab(targetName) {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        if(this.editableTabs.length!==0)
          this.$emit("setBreadcrumb",this.editableTabsValue);
        else
          this.$emit("setBreadcrumb",0);
      }
    },
    components: {
   
    }
  };
  </script>
<style scoped>
  .content-body {
    height: 100%;
  }
  /* .el-tabs--card {
    height: calc(100vh - 135px);
  } */
  .el-tab-pane {
    height: calc(100vh - 145px);
  }


</style>
  